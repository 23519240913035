<template>
  <div>
    <div class="mb-6">
      <a
        class="text-decoration-none text-subtitle-2 font-weight-bold"
        @click.prevent="returnBack"
      >
        <v-icon
          color="primary"
          dense
          class="mr-2"
        >
          {{ icons.mdiArrowLeft }}
        </v-icon>
        Kembali
      </a>
    </div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          title="Buku Favorit"
          subtitle="Daftar buku favorit"
          :no-add-button="noAddButton"
          :headers="headers"
          :items="favoriteBooks"
          :search="search"
          :page="page"
          :total-items="totalItems"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        ></DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import { mdiArrowLeft } from '@mdi/js'

export default {
  name: 'BookPurchaseHistory',
  components: {
    MainCard,
    DataTablePagination,
    SkeletonLoaderTable,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
      },
      noAddButton: true,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Buku', value: 'thumbnail-book' },
        { text: 'Jumlah Pinjaman', value: 'borrow' },
        { text: 'Kategori', value: 'categoryList' },
        { text: 'Subkategori', value: 'subcategoryList' },
        { text: 'Sub Subkategori', value: 'subsubcategoryList' },
        { text: 'Sub Subsubkategori', value: 'subsubsubcategoryList' },
      ],
      favoriteBooks: [],
      page: 1,
      library: '',
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      service: 'libraryfavorite',
      filterQuery: {
        library: '',
      },
      search: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.listFavoriteBooks()
      },
    },
    library: {
      handler() {
        this.listFavoriteBooks()
      },
    },
  },
  mounted() {
    this.listFavoriteBooks()
  },
  methods: {
    async listFavoriteBooks(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        page: this.page,
        library: this.library,
      }).then(
        ({ data }) => {
          this.favoriteBooks = data.data.map((favoriteBook, index) => {
            favoriteBook.subcategory = []
            favoriteBook.subsubcategory = []
            favoriteBook.subsubsubcategory = []
            favoriteBook.subsubsubsubcategory = []
            favoriteBook.subsubsubsubsubcategory = []
            favoriteBook.category.map((data, key) => {
              let categoryMap = []
              categoryMap.push(
                favoriteBook.category[key].parent_category?.parent_category?.parent_category?.parent_category,
              )
              categoryMap.push(favoriteBook.category[key].parent_category?.parent_category?.parent_category)
              categoryMap.push(favoriteBook.category[key].parent_category?.parent_category)
              categoryMap.push(favoriteBook.category[key].parent_category)
              categoryMap.push(favoriteBook.category[key])
              categoryMap = categoryMap.filter(item => item)
              categoryMap.forEach((item, index) => {
                if (index === 0) favoriteBook.category[key] = item
                else favoriteBook[`${'sub'.repeat(index)}category`].push(item)
              })

              return {
                ...favoriteBook,
                index: index + 1,
              }
            })

            return {
              ...favoriteBook,
              index: index + 1,
            }
          })
          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async searchHandler(search) {
      this.filterQuery.library = search
      this.page = 1
      await this.listFavoriteBooks(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    returnBack() {
      this.$router.back()
    },
  },
}
</script>

<style>
</style>
